<template>
  <button
    :class="classes"
    @click="onClick">
    <data-tag
      class="ui-card-shop__tag"
      hue="red"
      v-if="isClosed(shop)"
      v-scroll-reveal
    >{{ $t(`resto.closed`) }}</data-tag>

    <div class="ui-card-shop__banner">
      <ui-img
        v-if="banner"
        :alt="`${ shop.name } banner`"
        :src="banner"
        class="banner"
      />
    </div>

    <div class="ui-card-shop__body">
      <div class="ui-card-shop__logo">
        <ui-img
          v-if="logo"
          :alt="`${ shop.name } logo`"
          :src="logo"
          class="logo"
        />
      </div>

      <div class="ui-card-shop__details">
        <h4 class="ui-card-shop__name">{{ shop.name }}</h4>
        <div
          class="ui-card-shop__descr"
          v-html="nl2br(shop.description)"
        ></div>
      </div>
    </div>
  </button>
</template>

<script>
import { mapState } from 'vuex'
import UiImg from '@/components/ui/img'

import MixinOrderable from '@/helpers/orderable'
import MixinText from '@/helpers/text'

export default {
  name: 'CardShop',

  components: {
    UiImg
  },

  inject: ['$embed'],

  mixins: [ 
    MixinOrderable,
    MixinText 
  ],

  props: {
    shop: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState({
      _service: state => state['sayl-front.service'].service
    }),

    banner() {
      return this.shop?.banners[0] ?? this.$embed.shop.model?.banners[0]
    },
    
    classes() {
      return {
        'ui-card-shop': true,

        '-is-closed': this.isClosed(this.shop)
      }
    },

    logo() {
      return this.shop?.logo ?? this.$embed.shop.model?.logo
    }
  },

  methods: {
    onClick() {
      this.$emit('click', this.shop)
    }
  },
}
</script>
