<template>
  <section class="view-catalog-list">
    <div
    class="view-catalog-list__intro"
    v-scroll-reveal>
      <div class="view-catalog-list__intro-content">
        <actions-button
          :appearance="$pepper.Appearance.SUBTLE"
          :href="previous.action"
          icon-pre="arrow-left"
          v-if="hasMultipleServices"
        >{{ previous.label }}</actions-button>

        <h2
          class="view-catalog-list__intro-title"
          v-html="title"
        ></h2>

        <p
          class="view-catalog-list__intro-description"
          v-html="description"
        ></p>
      </div>
    </div>

    <!-- Content -->
    <div class="container">
      <div class="-cols">
        <!-- <div class="view-catalog-list__content"> -->
          <div
            class="col flow view-catalog-body"
            :class="{ '-is-loading': shopsLoading }">
            <div
              class="view-catalog-body__loader flow"
              v-if="shopsLoading">
              <ui-loader />
            </div>

            <div
              class="view-catalog-body__shops"
              v-if="!shopsLoading && filteredSubshops && filteredSubshops.length > 0">
              <card-shop
                :key="$basil.uniqId(i)"
                :shop="shop"
                show-illustration
                @click="onCardClick"
                v-for="(shop, i) in filteredSubshops"
                v-scroll-reveal
              />
            </div>

            <div
              class="view-catalog-body__empty"
              v-if="!shopsLoading && (!filteredSubshops || filteredSubshops.length === 0)">
              <data-empty
                :appearance="$pepper.Appearance.CARD"
                :title="$t('resto.multishop_no_shops_available_title')"
                :description="$t('resto.multishop_no_shops_available_description')"
              />
            </div>

            <!-- Toolbar -->
            <catalog-toolbar v-if="!isCatalogReadonly"/>
          </div>

          <!-- Aside -->
          <aside class="col flow view-catalog-list__aside -desktop">
            <div class="view-catalog-list__aside-body">
              <catalog-cart
                class="cart"
                v-scroll-reveal
              />
            </div>
          </aside>
        <!-- </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

import CardShop from '@/modules/catalog/components/ui/card-shop.vue'
import CatalogCart from '@/modules/catalog/components/cart/cart-container'

import CatalogToolbar from '@/modules/catalog/components/catalog/toolbar'

import MixinMultishop from '@/helpers/multishop'

export default {
  name: 'ShopsView',

  components: {
    CardShop,
    CatalogCart,
    CatalogToolbar,
  },

  inject: [
    '$core'
  ],

  mixins: [
    MixinMultishop
  ],

  computed: {
    ...mapState({
      i18n: state => state['bootstrap'].i18n,
      services: state => state['sayl-front.service'].services,
    }),

    description() {
      return this.$t('resto.catalog_shops_description')
    },

    embed() {
      return this.$basil.get(this.$embed, 'embed.model')
    },

    isCatalogReadonly() {
      return this.$basil.get(this.oat, 'is_catalog_readonly', false)
    },

    hasMultipleServices() {
      return this.services && this.services.length > 1
    },

    previous() {
      return {
        label: this.$t('resto.selection_service'),
        action: { name: 'sayl-front-catalog.services' }
      }
    },

    title() {
      return this.$t('resto.catalog_shops_title')
    },
  },

  methods: {
    onCardClick(shop) {
      this.$store.dispatch('sayl-front.catalog.multishop/setShop', { locale: this.lang, service: this.service, shop: shop, $core: this.$core, })

      this.$router.push({
        name: 'sayl-front-catalog.catalog',
        params: {
          slug: shop.slug,
          embed: this.embed.id,
          service: this.service
        }
      }).catch(() => {})
    },
  },

  mounted() {
    this.$store.dispatch('sayl-front.catalog.multishop/setShop', { locale: this.lang, service: this.service, shop: null, $core: this.$core, })
  }
}
</script>
